// components/CalendlyPopupButton.tsx
import React from "react";
import { PopupButton } from "react-calendly";
import "./CalendlyPopupButton.css";

interface CalendlyPopupButtonProps {
  url?: string;
  text?: string;
  className?: string;
}

const CalendlyPopupButton: React.FC<CalendlyPopupButtonProps> = ({
  url = "https://calendly.com/suyog-des",
  text = "SCHEDULE A DEMO",
  className = "",
}) => {
  return (
    <PopupButton
      url={url}
      rootElement={document.getElementById("root") as HTMLElement}
      text={text}
      className={`calendly-button  ${className}`}
    />
  );
};

export default CalendlyPopupButton;
