function setFavicon(url: string): void {
  // Check if a favicon link already exists
  let favicon = document.querySelector<HTMLLinkElement>("link[rel*='icon']");

  if (!favicon) {
    // If not, create a new link element
    favicon = document.createElement("link");
    favicon.rel = "icon";
    document.head.appendChild(favicon);
  }

  // Set the href of the favicon link to the provided URL
  favicon.href = url;
}


export default setFavicon