import React, { useState } from "react";
import "./ShowMoreText.css";

interface ShowMoreTextProps {
  text: string;
  maxLength: number;
  type?: string;
}

const ShowMoreText: React.FC<ShowMoreTextProps> = ({
  text,
  maxLength,
  type,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedText = isExpanded ? text : text.slice(0, maxLength) + "...";

  return (
    <p className="result-desc demo">
      {type == "bullet_point" ? (
        <ul className="bullet-point-summary">
          {displayedText.split("###").map((item) => {
            let temp = item.trim();
            if (temp.length > 0) {
              return <li>{item}</li>;
            }
          })}
        </ul>
      ) : (
        displayedText
      )}

      {text.length > maxLength && (
        <p className="showmore-button" onClick={toggleExpansion}>
          {isExpanded ? "Show Less" : "Show More"}
        </p>
      )}
    </p>
  );
};

export default ShowMoreText;
