import React, { createContext, useContext, useState, ReactNode } from "react";

interface SearchButtonContextType {
  isActive: boolean;
  setIsActive: Function;
}

const SearchButtonContext = createContext<SearchButtonContextType | undefined>(
  undefined
);

export const SearchButtonProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <SearchButtonContext.Provider value={{ isActive, setIsActive }}>
      {children}
    </SearchButtonContext.Provider>
  );
};

export const useSearchButtonContext = () => {
  const context = useContext(SearchButtonContext);

  if (context == undefined) {
    throw new Error("Something Went Wrong");
  }

  return context;
};
