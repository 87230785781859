import React, { useRef, useState, KeyboardEvent, useEffect } from "react";
import "./SearchBar.css";
import { IoSearch } from "react-icons/io5";
import { VscSend } from "react-icons/vsc";
import useEventCapture from "../../hooks/useEventCapture.tsx";
import { useLocation } from "react-router-dom";
import { useSearchButtonContext } from "../../context/SearchButtonContext.tsx";
import { useSummaryContext } from "../../context/SummaryContext.tsx";

interface SearchBarProps {
  fetchData: Function;
}

const SearchBar: React.FC<SearchBarProps> = ({ fetchData }) => {
  const [text, setText] = useState("");
  const location = useLocation();
  const buttonRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const { sendEvent } = useEventCapture();
  const { isActive, setIsActive } = useSearchButtonContext();
  const { setSummary } = useSummaryContext();
  // const { token } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (text.length > 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [text]);

  const onVisible = () => {
    const companyParam = new URLSearchParams(location.search).get("company");
    // const companyParam = searchParams.get("company");
    sendEvent({
      event_type: "searchbar_visible",
      session_id: sessionStorage.getItem("sessionId") || "",
      // token: token || "",
      page: "search_page",
      company: companyParam || "",
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onVisible();
        }
      },
      { threshold: 0.5 }
    );

    if (searchBarRef.current) {
      observer.observe(searchBarRef.current);
    }

    return () => {
      if (searchBarRef.current) {
        observer.unobserve(searchBarRef.current);
      }
    };
  }, []);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isActive) {
      buttonRef.current?.click();
    }
  };

  return (
    <div className={`searchbar`} ref={searchBarRef}>
      <div className="searchbar-input-field">
        <IoSearch className={`search-icon }`} />
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={handleKeyPress}
          type="text"
          placeholder="Ask me anything"
          className={`search-input`}
        />
      </div>
      <div
        className={`search-button  ${!isActive && "disabled"}`}
        ref={buttonRef}
        onClick={() => {
          if (isActive) {
            setSummary("");
            fetchData({ text: text });
          }
        }}
      >
        <VscSend />
      </div>
    </div>
  );
};

export default SearchBar;
