import axios from "axios";

const useEventCapture = () => {
  const eventCaptureUrl = process.env.REACT_APP_EVENT_CAPTURE_BACKEND_URL!;
  const sendEvent = (payload: { [key: string]: string }) => {
    axios
      .post(`${eventCaptureUrl}/event`, payload)
      .catch((err) => console.log(err));
  };

  return { sendEvent };
};

export default useEventCapture;
