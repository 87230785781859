import React, { useState, useRef, ReactNode, createContext } from "react";
import { io } from "socket.io-client";
import { useSearchButtonContext } from "./SearchButtonContext.tsx";

interface SummaryContextType {
  summary: string;
  loadingSummary: boolean;
  setSummary: Function;
  fetchSummary: (queryParams: { [key: string]: string }) => void;
}

const SummaryContext = createContext<SummaryContextType | undefined>(undefined);

export const SummaryContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [summary, setSummary] = useState<string>("");
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const { setIsActive } = useSearchButtonContext();
  const socketRef = useRef<any>(null); // Establish connection outside the component to avoid multiple connections

  const fetchSummary = (queryParams: { [key: string]: string }) => {
    if (socketRef.current) socketRef.current.disconnect();
    const backendUrl = "https://d3rak0r4lwho1b.cloudfront.net";
    setSummary(""); // Optionally reset summary
    socketRef.current = io(backendUrl, { transports: ["websocket"] });
    setLoadingSummary(true);

    // Setup listeners only when fetchSummary is called
    socketRef.current.on("summary_part", (data: { summary_part: string }) => {
      setSummary((prevSummary) => `${prevSummary}${data.summary_part}`);
      setLoadingSummary(false);
    });

    socketRef.current.on("summary_complete", () => {
      setLoadingSummary(false);
      setIsActive(true);
      socketRef.current.disconnect(); // Disconnect the socket when the stream ends
    });

    socketRef.current.connect(); // Ensure the socket is connected before emitting
    socketRef.current.emit(
      "get_summary_with_cache",
      JSON.stringify(queryParams)
    );

    // Cleanup listeners when the summary is fetched
    return () => {
      socketRef.current.off("summary_part");
      socketRef.current.off("summary_complete");
    };
  };

  return (
    <SummaryContext.Provider
      value={{ summary, setSummary, loadingSummary, fetchSummary }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

export const useSummaryContext = () => {
  const context = React.useContext(SummaryContext);

  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketContextProvider");
  }

  return context;
};
