import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./context/DataContext.tsx";
import { SummaryContextProvider } from "./context/SummaryContext.tsx";
import { SearchButtonProvider } from "./context/SearchButtonContext.tsx";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SearchButtonProvider>
        <SummaryContextProvider>
          <DataProvider>
            <App />
          </DataProvider>
        </SummaryContextProvider>
      </SearchButtonProvider>
    </BrowserRouter>
  </React.StrictMode>
);
