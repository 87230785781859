import React, { ReactNode, useContext, createContext, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useSearchButtonContext } from "./SearchButtonContext.tsx";
import useEventCapture from "../hooks/useEventCapture.tsx";
import { useSummaryContext } from "./SummaryContext.tsx";
// import { TileData } from "../components/Tile/Tile";

export interface TileData {
  image: string;
  title: string;
  description: string;
  label: string;
  link: string;
  id: string;
}

interface MarketingMessage {
  link: string;
  message: string;
}

interface CTA {
  text: string;
  url: string;
  open_type?: string | null;
}

interface Data {
  responses: TileData[];
  marketing_message: MarketingMessage;
  sales_cta: CTA;
}

interface User {
  token?: string;
  role?: string;
  userId?: string;
}

interface DataContextType {
  data: Data;
  loading: boolean;
  error: string | null;
  requestId: string;
  question: string;
  user: User;
  setUser: Function;
  sessionId: string;
  setSessionId: Function;
  fetchData: (params: {
    text: string;
    eventType: string;
    sessionIdValue?: string;
  }) => void;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<Data>({
    responses: [],
    marketing_message: { link: "", message: "" },
    sales_cta: { text: "", url: "" },
  });
  const [user, setUser] = useState<User>({
    token: undefined,
    role: undefined,
  });
  const [sessionId, setSessionId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [requestId, setRequestId] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const { setIsActive } = useSearchButtonContext();
  const { fetchSummary } = useSummaryContext();
  const { sendEvent } = useEventCapture();

  const fetchData = async ({
    text,
    sessionIdValue,
    eventType,
  }: {
    text: string;
    sessionIdValue?: string;
    eventType: string;
  }) => {
    setIsActive(false);
    const requestID = uuidv4();
    setRequestId(requestID);
    setQuestion(text);
    try {
      setLoading(true);
      const queryParams: { [key: string]: string } = {
        query: text,
        company: "riskified",
        requestId: requestID,
        number_of_results: "6",
      };
      if (sessionIdValue) {
        queryParams["sessionId"] = sessionIdValue;
      }

      sendEvent({
        event_type: eventType,
        session_id: sessionStorage.getItem("sessionId") || "",
        page: "home_page",
        company: "riskified",
        request_id: requestID,
        query: text,
        location: "floating",
      });

      const res = await axios.post<Data>(
        `https://d3rak0r4lwho1b.cloudfront.net/post_query`,
        queryParams
      );
      setData(res.data);
      // fetchSummary function call here
      fetchSummary(queryParams);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(e.message);
      } else {
        setError("An unexpected error occurred");
      }
      alert(error);
    } finally {
      setLoading(false);
      setIsActive(true);
    }
  };

  return (
    <DataContext.Provider
      value={{ data, loading, error, requestId, question, fetchData, user, setUser, sessionId, setSessionId }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};
