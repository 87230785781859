import React from "react";
import "./HorizontalLoader.css";

interface HorizontalLoaderProps {
  type?: string;
}

const HorizontalLoader: React.FC<HorizontalLoaderProps> = ({ type }) => {
  return <div className={`horizontal-row ${type}`}></div>;
};

export default HorizontalLoader;
