import React from "react";
import "./StatusBar.css";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";

interface StatusBarProps {
  type: string;
  message: string;
  onClose: Function;
}

const StatusBar: React.FC<StatusBarProps> = ({ type, message, onClose }) => {
  return (
    <div className={`bar ${type}`}>
      <div className="message">
        {type == "error" ? (
          <MdErrorOutline className={`bar-icon  ${type}`} />
        ) : (
          <FaCheck className={`bar-icon ${type}`} />
        )}
        <p>{message}!</p>
      </div>
      <IoMdClose className="bar-close-icon" onClick={() => onClose()} />
    </div>
  );
};

export default StatusBar;
