import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useDispatch } from 'react-redux';
// import { setUser } from '../../redux/reducers/userReducer';
import "./Unauthorized.css";
import { useDataContext } from "../../context/DataContext.tsx";

const Unauthorized: React.FC = () => {
  // const dispatch = useDispatch();
  const { setUser } = useDataContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the user data from Redux
    setUser(undefined);

    // Clear cookies
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "userRole=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Reload the page to clear any cached state
    window.location.reload();
  };

  return (
    <div className="unauthorized-container">
      <h1>Access Denied</h1>
      <p>You don't have permission to access this page.</p>
      <div className="button-group">
        <Link to="/" className="back-link">
          Back to Home
        </Link>
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
    </div>
  );
};

export default Unauthorized;
