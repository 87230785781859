import React, { useEffect } from "react";
import "./TermsOfUsage.css";
// import setFavicon from "../../utils/setFavicon";
// import setFavicon from "../../utils/setFavicon.tsx";
import WeblessFooter from "../../components/WeblessFooter/WeblessFooter.tsx";
import WeblessNavbar from "../../components/WeblessNavbar/WeblessNavbar.tsx";

const TermsOfUsage = () => {
  let termsOfUsage: SectionData[] = [
    {
      title: "1. Acceptance of Terms",
      content: `By accessing or using the Webless website ("Site") or any services provided by Webless ("Services"), you agree to comply with and be bound by these Terms of Use and our Privacy Policy. If you are using the Services on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to these Terms.`,
    },
    {
      title: "2. Services Provided",
      content: `Webless offers AI-powered personalization tools, including interactive prompts, personalized website experiences, and other related services (collectively, "Services"). The features and functionalities of the Services may vary based on the plan or agreement in place.`,
    },
    {
      title: "3. User Responsibilities",
      content:
        "You agree to use the Services only for lawful purposes and in accordance with these Terms. You agree not to:",
      items: [
        "Violate any applicable laws or regulations.",
        "Engage in any activity that could harm or disrupt the operation of the Services.",
        "Attempt to gain unauthorized access to any part of the Site or Services.",
        "Use the Services to transmit any malicious or harmful code.",
      ],
    },
    {
      title: "4. Account Registration",
      content:
        "To access certain features of the Services, you may be required to create an account. You agree to provide accurate, current, and complete information when creating your account and to update this information as necessary. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.",
    },
    {
      title: "5. Intellectual Property",
      content:
        "All content and materials available through Webless, including but not limited to text, graphics, logos, icons, images, audio clips, video clips, and software, are the property of Webless or its licensors and are protected by applicable intellectual property laws. You may not reproduce, distribute, modify, or create derivative works based on the content without prior written consent from Webless.",
    },
    {
      title: "6. Use of AI Prompts and Personalization",
      content: `Webless provides interactive prompts and personalized experiences powered by artificial intelligence ("AI"). While we strive to ensure the accuracy and relevance of these interactions, Webless does not guarantee that the AI-driven responses will always be accurate, complete, or free from errors. You acknowledge that the use of AI technology is subject to limitations and potential inaccuracies.`,
    },
    {
      title: "7. Data Collection and Privacy",
      content:
        "Webless collects, stores, and processes data in accordance with our Privacy Policy. By using the Services, you agree to the collection and use of your data as described in the Privacy Policy.",
    },
    {
      title: "8. Fees and Payments",
      content:
        "Certain features of the Services may be offered for a fee. You agree to pay any applicable fees for the Services as described in the pricing and payment terms. Webless reserves the right to change its pricing at any time, and you will be notified in advance of any such changes.",
    },
    {
      title: "9. Termination",
      content:
        "Webless may suspend or terminate your access to the Services at any time, for any reason, without notice, including for breach of these Terms. Upon termination, you will no longer have access to your account or any data associated with it.",
    },
    {
      title: "10. Limitation of Liability",
      content:
        "To the fullest extent permitted by law, Webless will not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or other intangible losses arising from your use of or inability to use the Services, even if Webless has been advised of the possibility of such damages.",
    },
    {
      title: "11. Indemnification",
      content:
        "You agree to indemnify, defend, and hold harmless Webless, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Services or breach of these Terms.",
    },
    {
      title: "12. Modifications to Terms",
      content:
        "Webless reserves the right to modify these Terms at any time. If we make changes, we will provide notice by updating the date at the top of these Terms. Your continued use of the Services after any such modifications constitutes your acceptance of the revised Terms.",
    },
    {
      title: "13. Governing Law",
      content:
        "These Terms are governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms will be brought exclusively in the courts located in the State of Delaware",
    },
    {
      title: "14. Contact Information",
      content:
        "If you have any questions or concerns regarding these Terms, please contact us at:",
      items: ["Email: contact@webless.ai"],
    },
  ];

  // setFavicon("/assets/images/webless_favicon.jpg");

  return (
    <>
      <div className="privacy-policy">
        <h1>Terms of Use</h1>
        {termsOfUsage.map((section, index) => (
          <div key={index}>
            <h2>{section.title}</h2>
            <p>{section.content}</p>
            {section.items && (
              <ul>
                {section.items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
            {section.subsections?.map((subsection, idx) => (
              <ListSection
                key={idx}
                title={subsection.title}
                description={subsection.description}
                description2={subsection.description2}
                items={subsection.items}
                isOrdered={subsection.isOrdered}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default TermsOfUsage;

// Define interfaces for section props
interface ListSectionProps {
  title: string;
  description: string;
  description2?: string;
  items?: string[];
  isOrdered?: boolean;
}

interface SectionData {
  title: string;
  content: string;
  content2?: string;
  subsections?: ListSectionProps[];
  items?: string[];
}

// ListSection component for rendering list items
const ListSection: React.FC<ListSectionProps> = ({
  title,
  description,
  description2,
  items,
  isOrdered = false,
}) => (
  <section>
    <h3>{title}</h3>
    <p>{description}</p>
    {items &&
      (isOrdered ? (
        <ol>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      ) : (
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ))}
    {description2 && <p>{description2}</p>}
  </section>
);
