import React from "react";
import "./Spinner.css";

interface SpinnerProps {
  type?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ type }) => {
  return <div className={`spinner ${type}`}></div>;
};

export default Spinner;
