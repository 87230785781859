import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase.ts";

export const loginUser = async (
  email: string,
  password: string
): Promise<{ token: string; userId: string; role: string | null } | Error> => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const token = await userCredential.user.getIdToken();
    const userId = userCredential.user.uid;

    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRole = userData.role;

        // Store role in cookie
        document.cookie = `userRole=${userRole}; path=/`;

        return {
          token,
          userId,
          role: userRole,
        };
      } else {
        return { token, userId, role: null };
      }
    } catch (firestoreError) {
      console.error("Firestore Error:", firestoreError);
      return { token, userId, role: null };
    }
  } catch (error) {
    return error as Error;
  }
};
