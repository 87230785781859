import React from "react";
// import { Link } from "react-router-dom";
// import CalendlyPopupButton from "../Calendly/CalendlyPopupButton";
// import Button from "../Button/Button";
import "./WeblessNavbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import CalendlyPopupButton from "../CalendlyPopupButton.tsx";
import weblessLogo from "../../assets/images/webless_logo.jpg";
// import CalendlyPopupButton from "../Calendly/CalendlyPopupButton";

const WeblessNavbar = () => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  return (
    <nav className="navbar-container">
      <div className="navbar-left-container">
        <div className="nav-logo-container">
          <a href="https://webless.ai/" target="_blank">
            <img src={weblessLogo} alt="Webless Logo" />
          </a>
        </div>
      </div>

      <div className="navbar-container-right">
        <div className="navbar-right-container-hamburger">
          <GiHamburgerMenu onClick={() => setIsOptionsOpen((prev) => !prev)} />
          {isOptionsOpen && (
            <div className="hamburger-options">
              {/* <Link to="/" className="hamburger-link">
                Home
              </Link> */}
              {/* <Link to="/products" className="hamburger-link">
                Products
              </Link>
              <Link to="/resources" className="hamburger-link">
                Resources
              </Link>
              <Link to="/about" className="hamburger-link">
                About
              </Link> */}
              {/* <CalendlyPopupButton className="filled" /> */}
            </div>
          )}
        </div>
        <div className="navbar-right-container-buttons-wrapper">
          {/* <Button text="Login" type="" action={() => {}} /> */}
          <CalendlyPopupButton className="filled" />
        </div>
      </div>
    </nav>
  );
};

export default WeblessNavbar;
