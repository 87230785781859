import React, { useState } from "react";
import "./SearchTile.css";
import EditButton from "../EditButton/EditButton.tsx";
import TileSidePannel from "../SidePannel/TileSidePannel/TileSidePannel.tsx";
import Button from "../Button/Button.tsx";

export interface CardProps {
  title: string;
  imgUrl: string;
  desc: string;
  link: string;
  tileId: string;
  handleTileClick: (link: string, tileId: string) => void;
}

const SearchTile: React.FC<CardProps> = ({
  title,
  imgUrl,
  desc,
  link,
  tileId,
  handleTileClick,
}) => {
  const [showEditTile, setShowEditTile] = useState<boolean>(false);

  return (
    <div
      className="tile search-tile"
      // onClick={() => handleTileClick(link, tileId)}
    >
      <TileSidePannel
        tileId={tileId}
        tileData={{
          title,
          image: imgUrl,
          description: desc,
          link,
          id: tileId,
          label: "",
        }}
        showEditTile={showEditTile}
        setShowEditTile={setShowEditTile}
      />
      <div className="tile-image-container">
        <img className="tile-image" src={imgUrl} alt="" />
        <p className="tile-title">{title}</p>
        <p className="tile-desc">{desc}</p>
      </div>
      <div className="edit-button-wrapper" onClick={(e) => e.stopPropagation()}>
        <Button text="View" onClick={() => handleTileClick(link, tileId)} />

        <EditButton
          text="Edit"
          onClick={() => {
            setShowEditTile(true);
          }}
        />
      </div>
    </div>
  );
};

export default SearchTile;
