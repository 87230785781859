import React, { useState } from "react";
import "./SummarySidePannel.css";
import Button from "../../Button/Button.tsx";
import { MdClose } from "react-icons/md";
import { BiComment } from "react-icons/bi";
import { VscListFlat } from "react-icons/vsc";
import { useSummaryContext } from "../../../context/SummaryContext.tsx";
import { useDataContext } from "../../../context/DataContext.tsx";
import axios from "axios";
import { PostStatus } from "../CTASidePannel/CTASidePannel.tsx";
import StatusBar from "../../StatusBars/StatusBar.tsx";

interface SummarySidePannelProps {
  showEditSummary: boolean;
  setShowEditSummary: Function;
}

const SummarySidePannel: React.FC<SummarySidePannelProps> = ({
  showEditSummary,
  setShowEditSummary,
}) => {
  const { summary } = useSummaryContext();
  const { requestId, user, sessionId } = useDataContext();
  const [value, setValue] = useState<string>("");
  const [comment, setComment] = useState("");
  const [postStatus, setPostStatus] = useState<PostStatus | undefined>(
    undefined
  );

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleSummaryUpdate = async () => {
    setPostStatus(undefined);
    try {
      await axios
        .post(
          "https://d180ugeovs1weq.cloudfront.net/feedback",
          {
            feedback_type: "response",
            session_id: sessionId,
            request_id: requestId,
            current_response: summary,
            suggested_response: value,
            additional_comments: comment,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          setValue("");
          setComment("");
          if (res.status == 200) {
            setPostStatus({
              status: "success",
              message: "Response saved succesfully!",
            });
          } else {
            setPostStatus({
              status: "error",
              message: "Error Saving Response",
            });
          }
        });
      // Handle success (e.g., show a success message)
    } catch (error) {
      setPostStatus({
        status: "error",
        message: "Error Saving Response",
      });
    }
  };

  return (
    <div
      className={`summary-slider-container-wrapper  ${
        showEditSummary ? "show" : ""
      }`}
      onClick={(e) => {
        e.preventDefault();
        setShowEditSummary(false);
        setPostStatus(undefined);
      }}
    >
      <div
        className={`summary-slider-container ${showEditSummary ? "show" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="slider-heading-container">
          <p className="slider-heading">Edit response</p>
          <MdClose
            className="slider-close-button"
            onClick={() => setShowEditSummary(false)}
          />
        </div>
        <hr className="slider-hr" />
        <div className="edit-section">
          <table className="summary-table">
            <tbody>
              <tr>
                <td>
                  <VscListFlat />
                </td>
                <td>Current response</td>
                <td>{summary}</td>
              </tr>
              <tr>
                <td>
                  <VscListFlat />
                </td>
                <td>Suggested response</td>
                <td>
                  <textarea
                    name=""
                    value={value}
                    onChange={handleChange}
                    placeholder="Type or paste your new response here"
                    id=""
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <BiComment />
                </td>
                <td>Additional Comments</td>
                <td colSpan={2}>
                  <textarea
                    className="comment-textarea"
                    placeholder="Type your comments here"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button text="Save" disabled={!value && !comment} onClick={handleSummaryUpdate} />
        </div>
        <div className="status-bar-container">
          {postStatus && (
            <StatusBar
              type={postStatus?.status}
              message={postStatus?.message}
              onClose={() => setPostStatus(undefined)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SummarySidePannel;
