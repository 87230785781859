import React from "react";
import { Route, Routes } from "react-router-dom";
import TermsOfUsage from "../pages/TermsOfUsage/TermsOfUsage.tsx";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy.tsx";
import Unauthorized from "../pages/Unauthorized/Unauthorized.tsx";
import ProtectedRoute from "./ProtectedRoute.tsx";
import Home from "../pages/Home/Home.tsx";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<h1>Welcome...</h1>} />
        <Route path="/terms-of-use" element={<TermsOfUsage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          path="/riskified"
          element={
            <ProtectedRoute
              allowedRoles={[
                "webless_admin",
                "riskified_admin",
                "riskified_user",
              ]}
            >
              <Home />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default AllRoutes;
