import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home/Home.tsx";
import WeblessFooter from "./components/WeblessFooter/WeblessFooter.tsx";
import { FaLinkedin } from "react-icons/fa";
import WeblessNavbar from "./components/WeblessNavbar/WeblessNavbar.tsx";
import { useDataContext } from "./context/DataContext.tsx";
import { useCookies } from "react-cookie";
import LoginPopup from "./components/Popup/LoginPopup.tsx";
import AllRoutes from "./routes/AllRoutes.tsx";
import { v4 as uuidv4 } from "uuid";
import useEventCapture from "./hooks/useEventCapture.tsx";

function App() {
  const [showPopup, setShowPopup] = useState(true);
  const [cookies] = useCookies(["token", "userRole"]);
  const { user, setUser, sessionId, setSessionId } = useDataContext();
  const { sendEvent } = useEventCapture();

  useEffect(() => {
    const tempSessionId = uuidv4();
    let temp = sessionStorage.getItem("sessionId");
    if (temp !== null) {
      setSessionId(temp);
    } else {
      sessionStorage.setItem("sessionId", tempSessionId);
      setSessionId(tempSessionId);
      sendEvent({
        event_type: "session_start",
        page: "home_page",
      });
    }
  }, []);

  useEffect(() => {
    if (cookies?.token?.token) {
      setUser({
        token: cookies.token.token,
        role: cookies.userRole || null,
      });
    }
  }, [cookies, setUser]);

  useEffect(() => {
    if (user.token == null) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [user]);

  return (
    <div className="App">
      <WeblessNavbar />
      {showPopup ? <LoginPopup /> : <AllRoutes />}
      <WeblessFooter />
    </div>
  );
}

export default App;
