import React, { useState } from "react";
import "./LoginPopup.css";
import { useCookies } from "react-cookie";
// import { loginUser } from "../../redux/actions/userActions";
// import { AppDispatch } from "../../redux/store";
// import { useDispatch } from "react-redux";
import setFavicon from "../../utils/setFavicon";
import { loginUser } from "../../utils/loginUser.tsx";
import weblessLogo from "../../assets/images/webless_logo.jpg";
import { useDataContext } from "../../context/DataContext.tsx";

interface LoginProps {}

const LoginPopup: React.FC<LoginProps> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  // const dispatch: AppDispatch = useDispatch();
  const [token, setToken, removeToken] = useCookies(["token"]);
  const { setUser } = useDataContext();

  const validateForm = () => {
    let isValid = true;
    setEmailError(null);
    setPasswordError(null);
    setError(null);

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      isValid = false;
    }

    return isValid;
  };

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      setIsLoading(true);
      setError(null);
      const resultAction = await loginUser(email, password);

      if ("token" in resultAction && "role" in resultAction) {
        setUser({
          token: resultAction.token,
          role: resultAction.role,
          // userId: resultAction.userId;
        });
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        setToken("token", resultAction, {
          expires: date,
          secure: true,
          sameSite: "strict",
        });
      } else {
        // Handle the case where resultAction is an Error
        setError("An error occurred during login");
      }

      // if (loginUser.fulfilled.match(resultAction)) {
      //   const date = new Date();
      //   date.setTime(date.getTime() + 60 * 60 * 1000);
      //   setToken("token", resultAction.payload, {
      //     expires: date,
      //     secure: true,
      //     sameSite: "strict",
      //   });
      // } else {
      //   const errorMessage = resultAction.payload as any;
      //   setError(
      //     errorMessage?.code === "auth/wrong-password"
      //       ? "Invalid email or password"
      //       : errorMessage?.code === "auth/user-not-found"
      //       ? "No account found with this email"
      //       : "An error occurred during login. Please try again."
      //   );
      // }
    } catch (err) {
      setError("An unexpected error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      handleLogin({ email, password });
    }
  };

  // setFavicon("/assets/images/webless_favicon.jpg");

  return (
    <div className="popup">
      <form action="" className="popup-form" onSubmit={handleSubmit}>
        <img src={weblessLogo} className="login-page-logo" alt="Webless Logo" />

        {error && <div className="error-message">{error}</div>}

        <div className="form-group">
          <input
            type="email"
            className={`popup-login-email ${emailError ? "error" : ""}`}
            placeholder="Please enter your email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(null);
            }}
            disabled={isLoading}
          />
          {emailError && <div className="field-error">{emailError}</div>}
        </div>

        <div className="form-group">
          <input
            type="password"
            className={`popup-login-password ${passwordError ? "error" : ""}`}
            placeholder="Please enter your password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(null);
            }}
            disabled={isLoading}
          />
          {passwordError && <div className="field-error">{passwordError}</div>}
        </div>

        <button
          type="submit"
          className={`popup-login-btn ${isLoading ? "loading" : ""}`}
          disabled={isLoading}
        >
          {isLoading ? "" : "Login"}
        </button>
      </form>
    </div>
  );
};

export default LoginPopup;
