import React from 'react'
import "./EditButton.css"
import { MdEdit } from "react-icons/md";

interface EditButtonProps {
    text: string
    onClick: () => void
}

const EditButton:React.FC<EditButtonProps> = ({text, onClick}) => {
  return (
      <button className='edit-button' onClick={onClick}>
          <MdEdit/>
          {text}
    </button>
  )
}

export default EditButton