import React from "react";
import "./WeblessFooter.css";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const WeblessFooter = () => {
  return (
    <div className="webless-footer">
      <ul className="webless-footer-left-section">
        <li>
          <Link className="link-tag" to={"/privacy-policy"}>
            Privacy Policy
          </Link>
        </li>
        <hr />
        <li>
          <Link className="link-tag" to="/terms-of-use">
            Terms of Use
          </Link>
        </li>
      </ul>
      <div
        className="linkedin-icon"
        onClick={() => {
          window.open("https://www.linkedin.com/company/webless-ai/", "_blank");
        }}
      >
        <FaLinkedin />
      </div>
    </div>
  );
};
export default WeblessFooter;
