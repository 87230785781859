import React, { useState } from "react";
import "./CTASidePannel.css";
import { VscListFlat } from "react-icons/vsc";
import { MdClose } from "react-icons/md";
import { BiComment } from "react-icons/bi";
import Button from "../../Button/Button.tsx";
import { useDataContext } from "../../../context/DataContext.tsx";
import axios from "axios";
import StatusBar from "../../StatusBars/StatusBar.tsx";

interface CTASidePannelProps {
  showEditCTA: boolean;
  setShowEditCTA: Function;
}

export interface PostStatus {
  status: "success" | "error";
  message: string;
}

const CTASidePannel: React.FC<CTASidePannelProps> = ({
  showEditCTA,
  setShowEditCTA,
}) => {
  const { data, requestId, user, sessionId } = useDataContext();
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [comment, setComment] = useState("");
  const [postStatus, setPostStatus] = useState<PostStatus | undefined>(
    undefined
  );

  const resetState = () => {
    setTitle("");
    setUrl("");
    setComment("");
  };

  const handleCtaUpdate = async () => {
    setPostStatus(undefined);
    try {
      await axios
        .post(
          "https://d180ugeovs1weq.cloudfront.net/feedback",
          {
            feedback_type: "cta",
            session_id: sessionId,
            request_id: requestId,
            current_title: data.sales_cta.text,
            current_url: data.sales_cta.url,
            suggested_title: title,
            suggested_url: url,
            additional_comments: comment,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          resetState();
          if (res.status == 200) {
            setPostStatus({
              status: "success",
              message: "Response saved succesfully!",
            });
          } else {
            setPostStatus({
              status: "error",
              message: "Error Saving Response",
            });
          }
        });
    } catch (error) {
      setPostStatus({
        status: "error",
        message: "Error Saving Response",
      });
    }
  };

  return (
    <div
      className={`cta-slider-container-wrapper  ${showEditCTA ? "show" : ""}`}
      onClick={() => {
        setPostStatus(undefined);
        setShowEditCTA(false);
      }}
    >
      <div
        className={`cta-slider-container ${showEditCTA ? "show" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="slider-heading-container">
          <p className="slider-heading">Edit CTA</p>
          <MdClose
            className="slider-close-button"
            onClick={() => {
              setPostStatus(undefined);
              setShowEditCTA(false);
            }}
          />
        </div>
        <hr className="slider-hr" />
        <div className="edit-section">
          <table className="cta-table">
            <thead>
              <tr>
                <td></td>
                <td></td>
                <td>Title</td>
                <td>URL</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <VscListFlat />
                </td>
                <td>Current CTA</td>
                <td>{data.sales_cta.text}</td>
                <td>{data.sales_cta.url}</td>
              </tr>
              <tr>
                <td>
                  <VscListFlat />
                </td>
                <td>Suggested CTA</td>
                <td>
                  <input
                    type="text"
                    placeholder="CTA Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Paste URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <BiComment />
                </td>
                <td>Additional Comments</td>
                <td colSpan={2}>
                  <textarea
                    className="comment-textarea"
                    placeholder="Type your comments here"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            text="Save"
            disabled={!title && !url && !comment}
            onClick={handleCtaUpdate}
          />
        </div>
        <div className="status-bar-container">
          {postStatus && (
            <StatusBar
              type={postStatus?.status}
              message={postStatus?.message}
              onClose={() => setPostStatus(undefined)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CTASidePannel;
