import React, { useEffect, useState } from "react";
import "./TileSidePannel.css";
import Button from "../../Button/Button.tsx";
import { VscListFlat } from "react-icons/vsc";
import { TileData, useDataContext } from "../../../context/DataContext.tsx";
import { MdClose } from "react-icons/md";
import { BiComment } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useSummaryContext } from "../../../context/SummaryContext.tsx";
import axios from "axios";
import { PostStatus } from "../CTASidePannel/CTASidePannel.tsx";
import StatusBar from "../../StatusBars/StatusBar.tsx";

interface TileSidePannelProps {
  tileId: string;
  showEditTile: boolean;
  setShowEditTile: Function;
  tileData: TileData;
}

const TileSidePannel: React.FC<TileSidePannelProps> = ({
  showEditTile,
  setShowEditTile,
  tileId,
  tileData,
}) => {
  const { data, requestId, user, sessionId } = useDataContext();
  const { summary } = useSummaryContext();
  const [currentTile, setCurrentTile] = useState<TileData | null>();
  const [showHeading, setShowHeading] = useState<boolean>(false);
  const [headline, setHeadline] = useState("");
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [description, setDescription] = useState("");
  const [showUrl, setShowUrl] = useState<boolean>(false);
  const [url, setUrl] = useState("");
  const [showImage, setShowImage] = useState<boolean>(false);
  const [image, setImage] = useState("");
  const [headingPostStatus, setHeadingPostStatus] = useState<
    PostStatus | undefined
  >(undefined);
  const [summaryPostStatus, setSummaryPostStatus] = useState<
    PostStatus | undefined
  >(undefined);
  const [imagePostStatus, setImagePostStatus] = useState<
    PostStatus | undefined
  >(undefined);
  const [urlPostStatus, setUrlPostStatus] = useState<PostStatus | undefined>(
    undefined
  );

  const [headingComment, setHeadingComment] = useState("");
  const [summaryComment, setSummaryComment] = useState("");
  const [imageComment, setImageComment] = useState("");
  const [urlComment, setUrlComment] = useState("");

  useEffect(() => {
    let currentTile = data.responses.filter((item) => item.id === tileId);
    if (currentTile.length > 0) {
      setCurrentTile(currentTile[0]);
    }
  }, []);

  const handleHeadingUpdate = async () => {
    setHeadingPostStatus(undefined);
    try {
      await axios
        .post(
          "https://d180ugeovs1weq.cloudfront.net/feedback",
          {
            feedback_type: "tile",
            session_id: sessionId,
            request_id: requestId,
            tile_id: tileId,
            edit_type: "headline",
            current_healine: currentTile?.title,
            suggested_headline: headline,
            additional_comments: headingComment,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          setHeadline("");
          setHeadingComment("");
          if (res.status == 200) {
            setHeadingPostStatus({
              status: "success",
              message: "Response saved succesfully!",
            });
          } else {
            setHeadingPostStatus({
              status: "error",
              message: "Error Saving Response",
            });
          }
        });
      // Handle success (e.g., show a success message)
    } catch (error) {
      // if (axios.isAxiosError(error)) {
      //   console.error("Axios error:", error.response?.data);
      //   // Handle axios error (e.g., show an error message)
      // } else {
      //   console.error("Unexpected error:", error);
      //   // Handle unexpected error
      // }
      setHeadingPostStatus({
        status: "error",
        message: "Error Saving Response",
      });
    }
  };

  const handleTileSummaryUpdate = async () => {
    setSummaryPostStatus(undefined);
    try {
      await axios
        .post(
          "https://d180ugeovs1weq.cloudfront.net/feedback",
          {
            feedback_type: "tile",
            session_id: sessionId,
            request_id: requestId,
            tile_id: tileId,
            edit_type: "summary",
            current_summary: currentTile?.description,
            suggested_summary: description,
            additional_comments: summaryComment,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          setDescription("");
          setSummaryComment("");
          if (res.status == 200) {
            setSummaryPostStatus({
              status: "success",
              message: "Response saved succesfully!",
            });
          } else {
            setSummaryPostStatus({
              status: "error",
              message: "Error Saving Response",
            });
          }
        });
      // Handle success (e.g., show a success message)
    } catch (error) {
      setSummaryPostStatus({
        status: "error",
        message: "Error Saving Response",
      });
    }
  };

  const handleTileUrlUpdate = async () => {
    setUrlPostStatus(undefined);
    try {
      await axios
        .post(
          "https://d180ugeovs1weq.cloudfront.net/feedback",
          {
            feedback_type: "tile",
            session_id: sessionId,
            request_id: requestId,
            tile_id: tileId,
            edit_type: "url",
            current_url: currentTile?.link,
            suggested_url: url,
            additional_comments: urlComment,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          setUrl("");
          setUrlComment("");
          if (res.status == 200) {
            setUrlPostStatus({
              status: "success",
              message: "Response saved succesfully!",
            });
          } else {
            setUrlPostStatus({
              status: "error",
              message: "Error Saving Response",
            });
          }
        });
      // Handle success (e.g., show a success message)
    } catch (error) {
      setUrlPostStatus({
        status: "error",
        message: "Error Saving Response",
      });
    }
  };

  const handleTileImageUpdate = async () => {
    setImagePostStatus(undefined);
    try {
      await axios
        .post(
          "https://d180ugeovs1weq.cloudfront.net/feedback",
          {
            feedback_type: "tile",
            session_id: sessionId,
            request_id: requestId,
            tile_id: tileId,
            edit_type: "image",
            current_image: currentTile?.image,
            suggested_image: image,
            additional_comments: imageComment,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          setImage("");
          setImageComment("");
          if (res.status == 200) {
            setImagePostStatus({
              status: "success",
              message: "Response saved succesfully!",
            });
          } else {
            setImagePostStatus({
              status: "error",
              message: "Error Saving Response",
            });
          }
        });
      // Handle success (e.g., show a success message)
    } catch (error) {
      setImagePostStatus({
        status: "error",
        message: "Error Saving Response",
      });
    }
  };

  return (
    <div
      className={`tile-edit-slider-container-wrapper   ${
        showEditTile ? "show" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        setHeadingPostStatus(undefined);
        setSummaryPostStatus(undefined);
        setImagePostStatus(undefined);
        setUrlPostStatus(undefined);
        setShowHeading(false);
        setShowSummary(false);
        setShowImage(false);
        setShowUrl(false);
        setShowEditTile(false);
      }}
    >
      <div
        className={`tile-edit-slider-container  ${showEditTile ? "show" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="slider-heading-container">
          <p className="slider-heading">Edit content tile</p>
          <MdClose
            className="slider-close-button"
            onClick={() => {
              setHeadingPostStatus(undefined);
              setSummaryPostStatus(undefined);
              setImagePostStatus(undefined);
              setUrlPostStatus(undefined);
              setShowEditTile(false);
            }}
          />
        </div>
        <hr className="slider-hr" />
        <div className="edit-section">
          <div className="collapsible-edit-section">
            <div
              className="collapsible-edit-section-heading"
              onClick={() => {
                showEditTile && setHeadingPostStatus(undefined);
                setShowHeading(!showHeading);
              }}
            >
              <p>Heading</p>
              {showHeading ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            {showHeading && (
              <>
                <hr className="collapsible-edit-hr" />
                <table className="summary-table">
                  <tbody>
                    <tr>
                      <td>
                        <VscListFlat />
                      </td>
                      <td>Current headline</td>
                      <td>{currentTile?.title}</td>
                    </tr>
                    <tr>
                      <td>
                        <VscListFlat />
                      </td>
                      <td>Suggested headline</td>
                      <td>
                        <textarea
                          name=""
                          value={headline}
                          onChange={(e) => setHeadline(e.target.value)}
                          placeholder="Type or paste your new response here"
                          id=""
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <BiComment />
                      </td>
                      <td>Additional Comments</td>
                      <td colSpan={2}>
                        <textarea
                          className="comment-textarea"
                          placeholder="Type your comments here"
                          value={headingComment}
                          onChange={(e) => setHeadingComment(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  text="Save"
                  disabled={!headline && !headingComment}
                  onClick={handleHeadingUpdate}
                />
                <div className="status-bar-container">
                  {headingPostStatus && (
                    <StatusBar
                      type={headingPostStatus?.status}
                      message={headingPostStatus?.message}
                      onClose={() => setHeadingPostStatus(undefined)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="collapsible-edit-section">
            <div
              className="collapsible-edit-section-heading"
              onClick={() => {
                showSummary && setSummaryPostStatus(undefined);
                setShowSummary(!showSummary);
              }}
            >
              <p>Tile Summary</p>
              {showSummary ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            {showSummary && (
              <>
                <hr className="collapsible-edit-hr" />
                <table className="summary-table">
                  <tbody>
                    <tr>
                      <td>
                        <VscListFlat />
                      </td>
                      <td>Current tile Summary</td>
                      <td>{currentTile?.description}</td>
                    </tr>
                    <tr>
                      <td>
                        <VscListFlat />
                      </td>
                      <td>Suggested tile summary</td>
                      <td>
                        <textarea
                          name=""
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Type or paste your new tile summary here"
                          id=""
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <BiComment />
                      </td>
                      <td>Additional Comments</td>
                      <td colSpan={2}>
                        <textarea
                          className="comment-textarea"
                          placeholder="Type your comments here"
                          value={summaryComment}
                          onChange={(e) => setSummaryComment(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  text="Save"
                  disabled={!description && !summaryComment}
                  onClick={handleTileSummaryUpdate}
                />
                <div className="status-bar-container">
                  {summaryPostStatus && (
                    <StatusBar
                      type={summaryPostStatus?.status}
                      message={summaryPostStatus?.message}
                      onClose={() => setSummaryPostStatus(undefined)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="collapsible-edit-section">
            <div
              className="collapsible-edit-section-heading"
              onClick={() => {
                showImage && setImagePostStatus(undefined);
                setShowImage(!showImage);
              }}
            >
              <p>Image</p>
              {showImage ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            {showImage && (
              <>
                <hr className="collapsible-edit-hr" />
                <table className="summary-table">
                  <tbody>
                    <tr>
                      <td>
                        <VscListFlat />
                      </td>
                      <td>Current thumbnail</td>
                      <td>
                        <img
                          className="edit-thumbnail-image"
                          src={currentTile?.image}
                          alt=""
                        />
                        <p className="edit-thumbnail-link">
                          {currentTile?.image}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <VscListFlat />
                      </td>
                      <td>Suggested thumbnail</td>
                      <td>
                        <input
                          className="edit-thumbnail-input"
                          name=""
                          value={image}
                          onChange={(e) => setImage(e.target.value)}
                          placeholder="Paste image link"
                          id=""
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <BiComment />
                      </td>
                      <td>Additional Comments</td>
                      <td colSpan={2}>
                        <textarea
                          className="comment-textarea"
                          placeholder="Type your comments here"
                          value={imageComment}
                          onChange={(e) => setImageComment(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  text="Save"
                  disabled={!image && !imageComment}
                  onClick={handleTileImageUpdate}
                />
                <div className="status-bar-container">
                  {imagePostStatus && (
                    <StatusBar
                      type={imagePostStatus?.status}
                      message={imagePostStatus?.message}
                      onClose={() => setImagePostStatus(undefined)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="collapsible-edit-section">
            <div
              className="collapsible-edit-section-heading"
              onClick={() => {
                showUrl && setUrlPostStatus(undefined);
                setShowUrl(!showUrl);
              }}
            >
              <p>URL</p>
              {showUrl ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            {showUrl && (
              <>
                <hr className="collapsible-edit-hr" />
                <table className="summary-table">
                  <tbody>
                    <tr>
                      <td>
                        <VscListFlat />
                      </td>
                      <td>Current URL</td>
                      <td>{currentTile?.link}</td>
                    </tr>
                    <tr>
                      <td>
                        <VscListFlat />
                      </td>
                      <td>Suggested URL</td>
                      <td>
                        <input
                          name=""
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          placeholder="Paste your new URL here"
                          id=""
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <BiComment />
                      </td>
                      <td>Additional Comments</td>
                      <td colSpan={2}>
                        <textarea
                          className="comment-textarea"
                          placeholder="Type your comments here"
                          value={urlComment}
                          onChange={(e) => setUrlComment(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  text="Save"
                  disabled={!url && !urlComment}
                  onClick={handleTileUrlUpdate}
                />
                <div className="status-bar-container">
                  {urlPostStatus && (
                    <StatusBar
                      type={urlPostStatus?.status}
                      message={urlPostStatus?.message}
                      onClose={() => setUrlPostStatus(undefined)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TileSidePannel;
